$(document).ready(function() {
	$('.animated-icon3').click(function () {
		$(this).toggleClass('open');

		$('.navbar-brand').fadeToggle('fast');
	});

	$("#cookiebox").delay(400).fadeIn(600);

	$("#cookiebtn").click(function(e) {
        $("#cookiebox").fadeOut(600, function(){$(this).remove();});
		$.cookie("cookiemsg", "false", { expires: 365, path: '/' });
    });

	/*$('.isotope-grid').isotope({
		itemSelector: '.grid-item',
		percentPosition: true,
		masonry: {
			columnWidth: '.grid-sizer',
			gutter: 20,
			horizontalOrder: true
		}
	});*/
});
